import React, { useEffect } from 'react';
import { APIProvider, Map, MapEvent } from '@vis.gl/react-google-maps';
import ClientRouteMarkers from '../ClientRouteMarkers/ClientRouteMarkers';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  mapFullScreenClose,
  mapFullScreenOpen,
  mapRegular,
  mapSatellite,
} from '../../services/ampolitudeTracker';

const MapComponent = () => {
  const { clientLocation, emergency } = useSelector(
    (state: RootState) => state.emergency
  );
  const { user } = useSelector((state: RootState) => state.client);
  const [lat, lng] =
    clientLocation && emergency?.status === 'open'
      ? clientLocation.split(',')
      : emergency?.startLocation
        ? emergency.startLocation.split(',')
        : [47.040323, 28.85566];

  const handleMapTypeIdChanged = (e: MapEvent) => {
    const mapType = e.map?.getMapTypeId();
    if (mapType === 'hybrid') {
      mapSatellite(user, emergency);
    } else if (mapType === 'roadmap') {
      mapRegular(user, emergency);
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFullScreen = document.fullscreenElement;
      if (isFullScreen) {
        mapFullScreenOpen(user, emergency);
      } else {
        mapFullScreenClose(user, emergency);
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullScreenChange
      );
    };
  }, [user, emergency]);

  return (
    <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}`}>
      <Map
        defaultCenter={
          lat && lng ? { lat, lng } : { lat: 47.040323, lng: 28.85566 }
        }
        defaultZoom={15}
        id="main-map"
        mapId={'main-google-map'}
        streetViewControl={false}
        onMapTypeIdChanged={handleMapTypeIdChanged}
      >
        <ClientRouteMarkers
          clientLocation={
            emergency?.status === 'open'
              ? clientLocation
              : emergency?.startLocation
          }
          places={user?.places}
        />
      </Map>
    </APIProvider>
  );
};

export default MapComponent;
