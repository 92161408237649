import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEmergencyError,
  fetchEmergencyStart,
  resetEmergencyState,
  setEmergencyData,
} from '../reducers/emergencyReducer';
import { EmergencyService } from '../services/emergency';
import { getCode, isAuthenticated, logout } from '../services/auth';
import { REDIRECT_PATH } from '../constants/constants';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store/store';
import { resetClientState } from '../reducers/clientReducer';
import { logoutForce } from '../services/ampolitudeTracker';
import { toast } from 'react-toastify';

const useFetchEmergency = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, emergency } = useSelector(
    (state: RootState) => state.emergency
  );
  const { user } = useSelector((state: RootState) => state.client);

  const fetchEmergency = async () => {
    if (loading) return;

    const code = getCode();
    if (!isAuthenticated() || !code) {
      logoutForce(
        user,
        emergency,
        'Is not authenticated or does not have the code saved in local storage'
      );
      dispatch(resetClientState());
      dispatch(resetEmergencyState());
      logout();
      return navigate(REDIRECT_PATH);
    }

    dispatch(fetchEmergencyStart());

    const response = await EmergencyService.getEmergency(code);

    if (response.data) {
      dispatch(setEmergencyData(response.data));
    } else if (response.error) {
      dispatch(fetchEmergencyError(response.error.message));
      toast.error(response.error.message);
      logoutForce(user, emergency, response.error.message);
      dispatch(resetClientState());
      dispatch(resetEmergencyState());
      logout();
      navigate(REDIRECT_PATH);
    }
  };

  return fetchEmergency;
};

export default useFetchEmergency;
