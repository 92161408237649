import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardPage from './DashboardPage/DashboardPage';
import LanguageSelector from '../components/Language/LanguageSelector';
import LoginPage from './LoginPage/LoginPage';
// import WebSocketClient from '../components/websocket-client';

const AppRoutes: React.FC = () => {
  return (
    <div>
      <LanguageSelector />
      <div>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
        </Routes>
        {/*<WebSocketClient />*/}
      </div>
    </div>
  );
};

export default AppRoutes;
