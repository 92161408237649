import React, { useEffect, useState } from 'react';
import { useMap, AdvancedMarker } from '@vis.gl/react-google-maps';
import styles from '../Map/MapComponent.module.css';
import { ClientRouteMarkersProps } from '../../types/types';

const ClientRouteMarkers: React.FC<ClientRouteMarkersProps> = ({
  clientLocation,
  places,
}) => {
  const map = useMap();
  const [clientLatLng, setClientLatLng] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  useEffect(() => {
    if (clientLocation && map) {
      const coords = clientLocation.split(',').map(Number);
      if (coords.length === 2) {
        const newLatLng = { lat: coords[0], lng: coords[1] };
        setClientLatLng(newLatLng);

        map.setCenter(newLatLng);
      }
    } else {
      setClientLatLng(null);
    }
  }, [clientLocation, map]);

  return (
    <>
      {clientLatLng && (
        <AdvancedMarker position={clientLatLng} title="Client Location">
          <div className={styles['marker-content']}>SOS</div>
        </AdvancedMarker>
      )}

      {places?.map((place) => (
        <AdvancedMarker
          key={place.id}
          position={{
            lat: place.location.latitude,
            lng: place.location.longitude,
          }}
        >
          <div className={`${styles['marker-content']} ${styles['home']}`}>
            {place.name}
          </div>
        </AdvancedMarker>
      ))}
    </>
  );
};

export default ClientRouteMarkers;
