import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PanicComponent.module.css';
import { getCode, logout } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import useFetchEmergency from '../../actions/emergency';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { CHECK_INTERVAL, REDIRECT_PATH } from '../../constants/constants';
import { resetClientState } from '../../reducers/clientReducer';
import { resetEmergencyState } from '../../reducers/emergencyReducer';
import moment from 'moment';
import { logoutClosed } from '../../services/ampolitudeTracker';

const PanicScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emergency, clientLocation } = useSelector(
    (state: RootState) => state.emergency
  );
  const { user } = useSelector((state: RootState) => state.client);
  const code = getCode();
  const fetchEmergency = useFetchEmergency();

  const checkStatusUpdate = useCallback(async () => {
    try {
      await fetchEmergency();

      if (emergency?.status === 'closed') {
        if (code && emergency.openCode === code) {
          dispatch(resetClientState());
          dispatch(resetEmergencyState());
          logoutClosed(user, emergency);
          logout();
          navigate(REDIRECT_PATH);
        }
      }
    } catch (error) {
      console.error('Error checking status update:', error);
    }
  }, [fetchEmergency, emergency, clientLocation, code, navigate]);

  useEffect(() => {
    const intervalId = setInterval(checkStatusUpdate, CHECK_INTERVAL);

    return () => clearInterval(intervalId);
  }, [checkStatusUpdate, emergency?.openCode, code]);

  return (
    <div className={styles['panic-screen']}>
      <div className={styles['top-left']}>
        <h2>
          {t('insertedCode')}: {code}
        </h2>
      </div>
      <div className={styles['top-right']}>
        <h3>{t(emergency?.status === 'open' ? 'Open' : 'Closed')}</h3>
        <p>
          {t('startDateTime')}:{' '}
          {emergency &&
            moment(emergency.createdAt).format('DD/MM/YYYY, HH:mm:ss')}
        </p>
        {emergency?.closedAt && (
          <p>
            {t('closeDateTime')}:{' '}
            {moment(emergency.closedAt).format('DD/MM/YYYY, HH:mm:ss')}
          </p>
        )}
      </div>
    </div>
  );
};

export default PanicScreen;
